import { Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from '../../environments/environment';

const isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;
const isIframe = window !== window.parent && !window.opener;

/**
 * While testing on Local environment, change the 'redirectUri,postLogoutRedirectUri' to below provided,
 * "environment.msalConfigDetails.redirectUrls.frontEndLocal"
 */

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.msalConfigDetails.clientId, // Application (client) ID from the app registration
        authority: environment.msalConfigDetails.authority, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
        redirectUri: getRedirectUrl(), // This is your redirect URI
        postLogoutRedirectUri: getRedirectUrl()
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            logLevel: LogLevel.Verbose,
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
            piiLoggingEnabled: false
        },
    }
}

export function getRedirectUrl(): string {
    let envUrl = environment.msalConfigDetails.redirectUrls.envUrl;
    let smartEditUrl = environment.msalConfigDetails.redirectUrls.smartEdit;

    return isIframe ? smartEditUrl : envUrl;
}