export const errorConfiguration = {
    /*-- HomePage --*/
    GET_HomeProductCategories: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- PLP --*/
    GET_Products: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- Order History --*/
    GET_OrderHistoryList: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- Mini Cart --*/
    GET_MiniCart: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- Cart --*/
    GET_CartsList: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- Manage Carts --*/
    GET_ManageCartsList: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- Checkout --*/
    GET_CheckoutDeliveryMode: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- Order Details --*/
    GET_OrderDetailsList: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- Order Request --*/
    GET_OrderRequestList: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- My Profile --*/
    GET_MyProfile: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- Users --*/
    GET_UsersList: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    GET_LegalEntitiesList: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- User Profile --*/
    GET_FetchUserProfile: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- My Profile and User Profile --*/
    GET_UserLegalEntityList: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- Invite User --*/
    GET_CompanyList: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },
    /*-- Access Request --*/
    GET_AccessRequestList: {
        apiType: 'GET',
        url: null,
        handleAtComponent: false,
        handleAtGlobal: true,
        message: ''
    },

}

export enum ErrorType {
    WARNING = 'warning',
    ERROR = 'error'
}