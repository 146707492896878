import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, forkJoin, map, switchMap, throwError } from 'rxjs';
import { AppConstants } from '../constants/app-constant';
import { StorageService } from './storage.service';
import { MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  SilentRequest,
} from '@azure/msal-browser';
import {
  MSALAccountInformation,
  MSALResponsePayload,
  MSALAuthenticationResponse,
} from '../models/msalResponse';
import { AuthResponse } from '../models/authResponse';
import { AuthService, GlobalMessageService, GlobalMessageType, LanguageService } from '@spartacus/core';
import {
  AuthConfigService,
  AuthRedirectService,
  AuthStorageService,
  AuthToken,
  UserIdService,
} from '@spartacus/core';
import { ApiService } from './api.service';
import { LegalEntity } from '../models/legalEntityList';
import { DefaultLegalEntity } from '../models/defaultLegalEntity';
import { Options, Price, ProductVariant } from '../../SGRE-custom/product-details-page/models/product-info';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { msalConfig } from '../../SGRE-config/auth-config';
import { ErrorType } from '../../SGRE-config/error-config';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {

  // Loading Spinner
  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  //Add to cart Spinner
  id: BehaviorSubject<string> = new BehaviorSubject<string>("");

  // Login Banner @ Header
  loginBannerSubject = new BehaviorSubject<boolean>(this.showBanner());
  loginBanner$: Observable<boolean> = this.loginBannerSubject.asObservable();

  // Azure Token Refresh Flag
  azureTokenRefreshFlag = false;

  // Legal entities list
  private legalEntitiesSubject = new BehaviorSubject<any[]>(null);
  legalEntitiesList$ = this.legalEntitiesSubject.asObservable();

  // Default legal entity
  private defaultLegalEntitySubject = new BehaviorSubject<any[]>(null);
  defaultLegalEntitySubject$ = this.defaultLegalEntitySubject.asObservable();

  // Language dropdown @ Header
  public languageSubject = new BehaviorSubject<any>('');
  languageValue$ = this.languageSubject.asObservable();

  // Get carts by legalEntity (i.e. Mini Cart)
  private cartsByLegalEntitySubject = new BehaviorSubject<any>(null);
  carts$ = this.cartsByLegalEntitySubject.asObservable();

  // Get carts by legalEntity (i.e. Mini Cart)
  private activeCartSubject = new BehaviorSubject<any>(null);
  activeCart$ = this.activeCartSubject.asObservable();

  // Product Search API called after login
  productSearchSubject = new BehaviorSubject<boolean>(this.showBanner());
  productSearch$: Observable<boolean> = this.productSearchSubject.asObservable();

  // Product Details
  productDetailsSubject = new BehaviorSubject<boolean>(false);
  productDetail$: Observable<boolean> = this.productDetailsSubject.asObservable();

  // invoke Carts during ActiveCartChange
  updateCartsSubject = new BehaviorSubject<boolean>(this.showBanner());
  updateCart$: Observable<boolean> = this.updateCartsSubject.asObservable();

  // Manage Carts
  private manageCartsSubject = new BehaviorSubject<any>(null);
  manageCartsList$ = this.manageCartsSubject.asObservable();

  // manage carts pagination
  private manageCartsPaginationSubject = new BehaviorSubject<any>(null);
  manageCartsPaginationList$ = this.manageCartsPaginationSubject.asObservable();

  public manageCartsPaginationReset = new BehaviorSubject<any>(null);
  manageCartsPaginationReset$ = this.manageCartsPaginationReset.asObservable();

  public manageCartsSortFlag = false;

  // Get Order History List
  private orderHistorySubject = new BehaviorSubject<any>(null);
  ordersList$ = this.orderHistorySubject.asObservable();

  // Get Order History List 
  private orderHistoryCSVPDFSubject = new BehaviorSubject<any>(null);
  ordersCSVPDFList$ = this.orderHistoryCSVPDFSubject.asObservable();

  private orderSummarySubject = new BehaviorSubject<any>(null);
  orderSummary$ = this.orderSummarySubject.asObservable();

  private searchSuggestionsSubject = new BehaviorSubject<any>(null);
  suggestionsList$ = this.searchSuggestionsSubject.asObservable();

  public queryOrderSubject = new BehaviorSubject<any>(null);
  queryOrder$ = this.queryOrderSubject.asObservable();

  private addToCartSubject = new BehaviorSubject<any>(null);
  addToCart$ = this.addToCartSubject.asObservable();

  private recreateSubject = new BehaviorSubject<boolean>(false);
  recreate$ = this.recreateSubject.asObservable();
  public filteredData = [];

  public paginationReset = new BehaviorSubject<any>(null);
  pagination$ = this.paginationReset.asObservable();

  public orderRequestPaginationReset = new BehaviorSubject<any>(null);
  orderRequestPagination$ = this.orderRequestPaginationReset.asObservable();

  public topCategoriesSubject = new BehaviorSubject<any>(null);
  topCategories$ = this.topCategoriesSubject.asObservable();

  checkLEPresent = new BehaviorSubject<boolean>(false);
  checkLE$: Observable<boolean> = this.checkLEPresent.asObservable();

  public saveCartsFilter = [];
  public saveCartsSort = '';
  public saveCartsCurrentPage = 0;

  constructor(
    private msalService: MsalService,
    private authService: AuthService,
    private storageService: StorageService,
    protected authConfigService: AuthConfigService,
    protected authStorageService: AuthStorageService,
    protected userIdService: UserIdService,
    protected authRedirectService: AuthRedirectService,
    private apiService: ApiService,
    private router: Router,
    private globalMessageService: GlobalMessageService,
    private languageService: LanguageService,
    private datePipe: DatePipe
  ) { }

  /*-- Login functionality START --*/
  checkAndUpdateMsalData(msalResposne: any): void {
    const event = msalResposne as EventMessage;
    const payload = event.payload as AuthenticationResult;

    if (this.azureTokenRefreshFlag) {
      this.updateMsalInfo(msalResposne);
    } else {
      this.loadingSubject.next(true);
      this.updateMsalInfo(msalResposne);
      this.handleLoginApis(payload.idToken, payload.account.localAccountId);
    }
  }

  updateMsalInfo(msalResposne: any): void {
    const event = msalResposne as EventMessage;
    const payload = event.payload as AuthenticationResult;

    this.msalService.instance.setActiveAccount(payload.account);

    let accountInfo: MSALAccountInformation = {
      name: payload.account.name,
      username: payload.account.username,
      localAccountId: payload.account.localAccountId,
      login_hint: payload.idTokenClaims['login_hint']
    };

    let payloadInfo: MSALResponsePayload = {
      accessToken: payload.accessToken,
      expiresOn: payload.expiresOn,
      idToken: payload.idToken,
      account: accountInfo,
      scopes: payload.scopes
    };

    let msalObj: MSALAuthenticationResponse = {
      eventType: event.eventType,
      payload: payloadInfo,
    };

    this.storageService.msalInfo = msalObj;
  }

  handleLoginApis(idToken: string, userId: string) {
    this.fetchTokenAndLegalEntities(idToken, userId).subscribe(() => {
      this.loginComplete();
    });
  }

  getOAuthDetails(): { [k: string]: any } {
    let msalInfo = this.storageService.msalInfo;
    let idToken = msalInfo?.payload?.idToken;
    const tokenUrl = this.authConfigService.getTokenEndpoint();

    let params = new HttpParams();
    params = params.append('grant_type', environment.oAuthConfigDetails.grant_type);
    params = params.append('scope', environment.oAuthConfigDetails.scope);
    params = params.append('client_id', environment.oAuthConfigDetails.client_id);
    params = params.append('client_secret', environment.oAuthConfigDetails.client_secret);
    params = params.append('access_token', idToken);

    return { tokenUrl, params };
  }

  fetchTokenAndLegalEntities(jwtTokenVal: string, userIdVal: string): Observable<any> {
    const { tokenUrl, params } = this.getOAuthDetails();

    return this.apiService.fetchOAuthToken(tokenUrl, params).pipe(
      map((data: AuthResponse) => {
        let tokenObj: AuthToken = {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          expires_at: data.expires_in.toString(),
          granted_scopes: new Array(data.scope),
          access_token_stored_at: new Date().getTime().toString(),
          token_type: data.token_type,
        };
        this.authStorageService.setToken(tokenObj);
        this.userIdService.setUserId(userIdVal);
        this.loginBannerSubject.next(this.showBanner());
        /** Implement re-direct scenario here */
        let isBookmarkScenario = localStorage.getItem('isBookmarkScenario');
        if (isBookmarkScenario === 'true') {
          localStorage.removeItem('isBookmarkScenario');
          setTimeout(() => window.location.reload(), 1500);
        } else {
          let redirectUrl = (this.storageService.redirectUrl?.length > 0)
            ? this.storageService.redirectUrl
            : AppConstants.routeUrls.plp;
          this.router.navigate([redirectUrl])
          // this.authRedirectService.setRedirectUrl(redirectUrl);
          localStorage.removeItem(AppConstants.LocalStorageKeys.redirectUrl);
        }
      }),
      switchMap(() => this.fetchLegalEntities()),
      catchError(err => {
        this.loginBannerSubject.next(true);
        this.loadingSubject.next(false);
        return throwError(() => err)
      }),
    );
  }

  fetchLegalEntities() {
    let pageVal = 0;
    let fieldsVal = 'BASIC';
    let pageSizeVal = 200;
    let currentUserId = this.getUserId();

    return forkJoin(
      [
        this.apiService.getLegalEntityList(pageVal, fieldsVal, pageSizeVal, currentUserId),
        this.apiService.getDefaultLegalEntity('FULL'),
      ]
    )
      .pipe(
        map((res) => {
          let fullLegalEntityList: LegalEntity[] =
            res.at(0)?.legalEntities?.length > 0
              ? res.at(0).legalEntities.sort(this.sortDropdownOptions)
              : [];
          let defaultLegalEntityList: DefaultLegalEntity = res.at(1);
          let index = fullLegalEntityList.findIndex((entityObj) => entityObj.uid === defaultLegalEntityList.orgUnit.uid);
          if (index !== -1) {
            fullLegalEntityList.map((entityObj, i) => (entityObj.isSelected = i === index));
            this.storageService.defaultLegalEntity = fullLegalEntityList[index];
            this.checkLEPresent.next(true);
          } else {
            this.storageService.defaultLegalEntity = '';
            this.checkLEPresent.next(false);
          }
          this.storageService.userLegalEntities = fullLegalEntityList;
          this.storageService.userRoles = (index !== -1) ? fullLegalEntityList[index]?.roles : [];
          this.languageService.setActive(this.storageService.selectedLanguage || res.at(1).preferredLanguage?.isocode)
          this.storageService.dateFormat = res.at(1).dateFormat ? res.at(1).dateFormat : "MM-dd-yyyy";
          return res;
        }),
        catchError(err => {
          this.loadingSubject.next(false);
          return throwError(() => err);
        }),
      )
  }

  loginComplete() {
    let legalEntities = this.storageService.userLegalEntities;
    let legalEntityList = legalEntities
      ? legalEntities?.map((item) => item?.name)
      : [];
    this.legalEntitiesSubject.next(legalEntityList);
    let defaultLegalEntity = this.storageService.defaultLegalEntity;
    this.defaultLegalEntitySubject.next(defaultLegalEntity);
    if (defaultLegalEntity) {
      this.checkLEPresent.next(true);
    } else {
      this.checkLEPresent.next(false);
    }
  }
  /*-- Login functionality END --*/

  isUserLoggedIn(): boolean {
    let flag: boolean;
    this.authService.isUserLoggedIn().subscribe((data) => (flag = data));
    return flag;
  }

  getUserId(): string {
    let str: string;
    this.userIdService.getUserId().subscribe((data) => (str = data));
    return str;
  }

  showBanner(): boolean {
    let flag: boolean;
    this.authService.isUserLoggedIn().subscribe((data) => (flag = !data));
    return flag;
  }

  checkSmartEdit(): boolean {
    const isIframe: boolean = window !== window.parent && !window.opener;
    return isIframe;
  }

  handleMsalRedirectUrls(isSmartEdit: boolean) {
    let envUrl = environment.msalConfigDetails.redirectUrls.envUrl;
    let smartEditUrl = environment.msalConfigDetails.redirectUrls.smartEdit;

    msalConfig.auth.redirectUri = isSmartEdit ? smartEditUrl : envUrl;
    msalConfig.auth.postLogoutRedirectUri = isSmartEdit ? smartEditUrl : envUrl;
  }

  // Get carts by legalEntity (i.e. Mini Cart)
  public getCartsListByLegalEntity(legalEntity = '', legalEntityUpdated = false, spinnerFlag?: boolean) {
    const legalEntityId = legalEntity ? legalEntity : this.storageService.defaultLegalEntity?.uid;
    let paramsObject = {
      legalEntities: legalEntityId,
      fields: 'FULL',
      currentPage: 0,
      pageSize: 5
    };
    this.loadingSubject.next(true);
    this.apiService.getCartsListByLegalEntity(paramsObject)
      .pipe(
        map(data => {
          if (data?.carts?.length > 0) {
            const index = data.carts.findIndex((cartObj) => cartObj?.legalEntity?.uid === legalEntityId && cartObj.isActiveCart === true);
            if (index !== -1) {
              let cartObj = data.carts[index];
              data.carts = data.carts.filter((v, i) => i !== index);
              data.carts = [cartObj, ...data.carts];
            }
          }
          return data;
        })
      )
      .subscribe({
        next: (data: any) => {
          data?.carts?.forEach((item, index) => item['isSelected'] = (index === 0));
          if (this.storageService.defaultLegalEntity || legalEntityUpdated) {
            this.storageService.carts = data.carts;
            this.storageService.activeCart = data.carts[0];
            this.storageService.cartId = data.carts[0]?.code;
          }
          this.cartsByLegalEntitySubject.next(data);
          (legalEntityUpdated)
            ? (spinnerFlag)
              ? this.checkAndTriggerPageActions(['HOME_ROUTE_CHECK', 'PLP_ROUTE_CHECK', 'PDP_ROUTE_CHECK', 'MANAGE_CART_ROUTE_CHECK'])
              : this.checkAndTriggerPageActions(['HOME_ROUTE_CHECK', 'PLP_ROUTE_CHECK', 'PDP_ROUTE_CHECK', 'MANAGE_CART_ROUTE_CHECK', 'OTHER'])

            : this.loadingSubject.next(false);
        },
        error: (error) => this.loadingSubject.next(false),
      });
  }

  updateLegalEntity(index, spinnerFlag = false) {
    const legalEntityListSession = this.storageService.userLegalEntities;
    if (legalEntityListSession?.length > 0) {
      legalEntityListSession.map(
        (entityObj, i) => (entityObj.isSelected = (i === index))
      );

      this.storageService.userLegalEntities = legalEntityListSession;
      this.storageService.defaultLegalEntity = legalEntityListSession[index];
      this.storageService.userRoles = legalEntityListSession[index]?.roles;

      let defaultLegalEntity = legalEntityListSession[index];
      this.defaultLegalEntitySubject.next(defaultLegalEntity);

      this.getCartsListByLegalEntity(defaultLegalEntity.uid, true, spinnerFlag);
    }
  }

  getProductUrl(urlVal: string): string {
    let str: string = urlVal;
    if (urlVal?.length > 0 && (urlVal.includes(ProductVariant.NEW) || urlVal.includes(ProductVariant.REFURBISHED))) {
      str = urlVal.includes(ProductVariant.NEW) ? urlVal.split(ProductVariant.NEW)?.at(0) : urlVal.split(ProductVariant.REFURBISHED)?.at(0);
    }
    return str;
  }

  fetchProductQuantityValue(data: Options, availbleQty: number): number {
    let quantity = (availbleQty > 0)
      ? ((data?.interval > 0) ? data?.interval : 1)
      : this.getUpdatedMinQty(data?.minQuantity, data?.interval);
    return quantity;
  }

  getUpdatedMinQty(minQuantity, interval) {
    minQuantity = minQuantity ? minQuantity : 1;
    interval = interval ? interval : 1;
    if (minQuantity <= interval) {
      return interval
    } else {
      return Math.ceil(minQuantity / interval) * interval;
    }
  }

  setActiveCart(cartsList: any[], cartObj: any, filterData = null) {
    let leUID = cartObj?.legalEntity?.uid
    if (!cartObj?.legalEntity?.uid) {
      const legalEntity = this.storageService.userLegalEntities.filter((ule) => ule.name === cartObj.legalEntity);
      leUID = legalEntity.at(0).uid;
    }
    let paramsObject = {
      legalEntityId: leUID,
      userId: this.getUserId(),
    };
    this.apiService.setActiveCart(cartObj.code, paramsObject)
      .subscribe((res) => {
        if (res.status === 201) {
          this.storageService.carts = cartsList;
          this.storageService.activeCart = cartsList[0];
          this.storageService.cartId = cartsList[0].code;
          this.activeCartSubject.next({ carts: cartsList });
          this.checkAndTriggerPageActions(['HOME_ROUTE_CHECK', 'PLP_ROUTE_CHECK', 'CART_ROUTE_CHECK']);
          if (filterData?.isSaveCart) {
            this.getSavedCartsList(filterData?.filterObject);
          }
        }
      });
  }

  checkAndTriggerPageActions(pages: string[]) {
    if (pages && Array.isArray(pages) && pages.length > 0) {
      for (const item of pages) {
        switch (item) {
          case 'HOME_ROUTE_CHECK':
            if (this.router.url.includes('/') || this.router.url.includes(AppConstants.routeUrls.home)) {
              this.topCategoriesSubject.next(true);
            }
            break;
          case 'PLP_ROUTE_CHECK':
            if (this.router.url.includes(AppConstants.routeUrls.plp)) {
              this.productSearchSubject.next(true);
            }
            break;
          case 'PDP_ROUTE_CHECK':
            let flag = false;
            flag = (Object.entries(AppConstants.breadcrumbUrls).some(([key, value]) => {
              (value === 'Product Details' && this.router.url.includes(key))
            })) || (new RegExp(/\/p\/[a-zA-Z0-9]+(?:_N|_R)?/, 'gi').test(this.router.url));
            if (flag) {
              // this.productDetailsSubject.next(true);
              window.location.reload();
            }
            break;
          case 'MANAGE_CART_ROUTE_CHECK':
            if (this.router.url.includes(AppConstants.routeUrls.manageCarts)) {
              if (!this.manageCartsSortFlag) {
                this.getSavedCartsList();
              } else {
                this.manageCartsPaginationReset.next(true);
              }
            }
            break;
          case 'CART_ROUTE_CHECK':
            if (this.router.url.includes(AppConstants.routeUrls.cart)) {
              this.updateCartsSubject.next(true);
            }
            break;
          default:
            this.loadingSubject.next(false);
            break;
        }
      }
    }

  }

  public createCart(paramsObject): Observable<any> {
    return this.apiService.createCart(paramsObject);
  }

  public addToCart(cartId, paramsObject = {}, requestObject = {}): Observable<any> {
    return this.apiService.addToCart(cartId, paramsObject, requestObject);
  }

  public getSavedCartsList(params = {}, applyParams = false) {
    const legalEntityListSession = this.storageService.userLegalEntities;
    if (legalEntityListSession?.length > 0) {
      if (params && Object.keys(params)?.length === 0) {
        params = {
          currentPage: 0,
          pageSize: 20,
          fields: 'FULL',
        };
        if (this.saveCartsFilter?.length > 0) {
          params['legalEntities'] = this.saveCartsFilter?.join(',');
        } if (this.saveCartsSort?.length > 0) {
          params['sort'] = this.saveCartsSort;
        } if (this.saveCartsCurrentPage > 0) {
          params['currentPage'] = this.saveCartsCurrentPage;
        }
      }
      let paramsObject = params;
      let tempArr: string[] = [];
      let totalRecords: number = 0;
      let legalEntityList = legalEntityListSession?.map((item) => item.uid);
      legalEntityList?.forEach(item => tempArr.push(item));
      if (!paramsObject['legalEntities'] && tempArr?.length > 0) {
        paramsObject['legalEntities'] = tempArr.join(',');
      }
      this.loadingSubject.next(true);
      this.apiService.getSavedCartsList(paramsObject)
        .pipe(
          map((data: any) => {
            totalRecords = data?.totalNumber;
            return data?.carts?.map((item, index) => this.mapManageCartsData(item, index))
          })
        )
        .subscribe((data) => {
          this.loadingSubject.next(false);
          this.manageCartsSubject.next(data);
          this.manageCartsPaginationSubject.next(totalRecords);
        });
    }
  }

  mapManageCartsData(savedCartData, index) {
    const legalEntity = this.storageService.defaultLegalEntity?.name;
    savedCartData['id'] = savedCartData?.code;
    savedCartData['index'] = index;
    savedCartData['cartName'] = savedCartData?.name ? savedCartData?.name : "-";
    savedCartData['legalEntity'] = savedCartData?.legalEntity?.name;
    savedCartData['items'] = savedCartData?.totalItems;
    savedCartData['price'] = savedCartData?.totalPrice?.value;
    savedCartData['currency'] = savedCartData?.totalPrice?.currencyIso;
    savedCartData['selected'] = legalEntity === savedCartData?.legalEntity && savedCartData?.isActiveCart;
    return savedCartData;
  }

  // For Creating new Cart if no carts for a LegalEntity
  public createNewCart(legalEntity: any): Observable<any> {
    let paramsObject = {
      legalEntityId: legalEntity,
      fields: 'FULL',
      cartName: 'New Cart',
    };
    return this.apiService.createNewCart(paramsObject);
  }

  // Get Order History List
  public getOrderHistoryList(params, flag = false) {
    let paramsObject = {
      pageSize: 1000,
      fields: 'FULL',
    };
    Object.keys(params)?.forEach((key) => { paramsObject[key] = params[key]; });
    this.apiService.getOrderHistoryList(paramsObject)
      .pipe(
        map((data: any) => {
          let results = data?.results?.map((item) =>
            this.mapOrderHistoryData(item)
          );
          data['results'] = results;
          return data;
        })
      )
      .subscribe({
        next: (data) => {
          if (!flag) {
            this.orderSummarySubject.next(data);
            this.orderHistorySubject.next(data);
          } else {
            this.searchSuggestionsSubject.next(data?.results);
          }
          this.loadingSubject.next(false);
        },
        error: (error) => {
          this.loadingSubject.next(false);
        },
      });
  }

  mapOrderHistoryData(orderHistoryData) {
    let approvedBy = orderHistoryData?.approvedBy
      ? orderHistoryData?.approvedBy.split('|')[1]
      : '';
    let requestedBy = orderHistoryData?.requestedBy
      ? orderHistoryData?.requestedBy.split('|')[1]
      : '';
    orderHistoryData['approvedBy'] = approvedBy != 'null' ? approvedBy : '';
    orderHistoryData['companyPo'] = orderHistoryData?.companySo;
    orderHistoryData['navigateUrlId'] = orderHistoryData?.orderId;
    orderHistoryData['sgreSo'] = orderHistoryData?.sgreSo
      ? orderHistoryData?.sgreSo
      : '';
    orderHistoryData['requestedBy'] = requestedBy != 'null' ? requestedBy : '';
    orderHistoryData['orderDate'] = orderHistoryData?.orderDate;
    orderHistoryData['requestedDate'] = orderHistoryData?.orderDate;
    orderHistoryData['orderTotal'] = orderHistoryData?.orderTotal;
    orderHistoryData['currency'] = orderHistoryData?.currency;
    orderHistoryData['orderStatus'] = this.mapOrderStatus(
      orderHistoryData?.orderStatus
    );
    orderHistoryData['details'] = 'Show order';
    orderHistoryData['company'] = orderHistoryData?.company;
    orderHistoryData['legalEntity'] = orderHistoryData?.legalEntity?.name;
    orderHistoryData['word-break'] = orderHistoryData.companyPo != 'null' ? true : false;
    return orderHistoryData;
  }

  mapOrderStatus(status) {
    if (status == 'A') return 'Not yet processed';
    else if (status === 'B') return 'Partially processed';
    else if (status === 'C') return 'Completely processed';
    else if (status === 'PENDING_APPROVAL') return 'Pending approval';
    else if (status === 'CANCELLED') return 'Cancelled';
    else if (status === 'REJECTED') return 'Rejected';
    else if (status === 'CANCELLING') return 'Cancelling';
    else if (status === 'APPROVED') return 'Approved';
    return status;
  }

  public getOrderHistoryCsvPdfList(params): Observable<any> {
    return this.apiService.getOrderHistoryList(params)
      .pipe(
        map((data: any) => {
          let results = data?.results?.map((item) =>
            this.mapOrderHistoryData(item)
          );
          data['results'] = results;
          return data;
        })
      )
  }

  getFormattedPrice(data: Price): string | null {
    let str: string = null;
    if (data?.formattedValue && data?.currencyIso) {
      if (data.currencyIso === 'USD' || data.currencyIso === 'EUR') {
        str =
          data.formattedValue.slice(1) + ' ' + data.formattedValue.slice(0, 1);
      } else if (data.currencyIso.length === 3) {
        str =
          data.formattedValue.slice(3) + ' ' + data.formattedValue.slice(0, 3);
      } else if (data?.value) {
        str = data.value.toString();
      }
    } else if (data?.value) {
      str = data.value.toString();
    }
    return str;
  }

  setLanguage(langValue) {
    this.languageSubject.next(langValue);
  }

  recreate(data: boolean) {
    this.recreateSubject.next(data);
  }

  // On Language/DateForm change-api call
  public addLanguageDateChange(requestObject): Observable<any> {
    return this.apiService.storeLanguageDate(requestObject)
  }

  sortDropdownOptions(a: any, b: any) {
    const nameA = a?.name?.toLowerCase();
    const nameB = b?.name?.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  changeDateFormat(formatDate, dateFormat) {
    try {
      return " " + this.datePipe.transform(formatDate, dateFormat);
    }
    catch (error) {
      console.error('Date format error:', error);
      return formatDate;
    }
  }

  clearMessagesOnDestroy(messageType: string = '') {
    switch (messageType) {
      case ErrorType.WARNING:
        this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_WARNING);
        break;
      case ErrorType.ERROR:
        this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
        break;
      default:
        this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_WARNING);
        this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
    }
  }
}
