import { VariantOptionQualifier } from "@spartacus/core";

export interface ProductInfo {
    baseOptions?: BaseOptions[];
    baseProduct?: string;
    categories?: Categories[];
    code: string;
    configurable: boolean;
    description?: string;
    images?: Images;
    interval?: number;
    maxQuantity?: number;
    minQuantity?: number;
    multidimensional?: boolean;
    name?: string;
    nameHtml?: string;
    numberOfReviews?: boolean;
    price?: Price;
    priceRange?: PriceRange;
    purchasable?: boolean;
    salesUnitType?: string;
    sapBlocked?: boolean;
    slug?: string;
    sourceSapSystem?: string;
    stock?: Stock;
    summary?: string;
    url?: string;
    variantMatrix?: any[];
    variantOptions?: Options[];
    variantType?: string;
    viewProductAvailability?: boolean;
}

export interface BaseOptions {
    options: Options[];
    selected: Options;
    variantType: string;
}

export interface Options {
    code: string;
    compatibility?: string[];
    discountPercentage?: number;
    discountPriceData?: DiscountPriceData;
    grossWeight?: number;
    grossWeightUnitCode?: string;
    height?: any;
    heightUnitCode?: string;
    interval?: number;
    length?: any;
    lengthUnitCode?: string;
    maxQuantity?: number;
    minQuantity?: number;
    name?: string;
    netWeight?: number;
    netWeightUnitCode?: string;
    obsoleteFlag?: boolean;
    packageSize?: number;
    priceData?: PriceData;
    quotationFlag?: boolean;
    refurbished?: boolean;
    sapBlocked?: boolean;
    stock?: Stock;
    technology?: string[];
    url?: string;
    variantOptionQualifiers?: any[];
    volume?: number;
    volumeUnitCode?: string;
    width?: number;
    widthUnitCode?: string;

    // additional properties
    quantityValue?: number | string;
    isMaxLmtReached?: boolean;
}

export interface DiscountPriceData {
    currencyIso: string;
    formattedValue: string;
    priceType: string;
    value: number;
}

export interface PriceData {
    currencyIso: string;
    value: number;
}

export interface Stock {
    estimatedLeadTime?: number;
    marketingStrategy?: string;
    stockLevel?: number;
    stockLevelStatus?: string;
}

export interface Categories {
    code: string;
    image: Image;
    name: string;
    url: string;
}

export interface Image {
    url: string;
}

export interface Images {
    PRIMARY: ImageTypePrimary;
    GALLERY: ImageTypeGallery[];
}

export interface ImageTypePrimary {
    product: PrimaryProduct;
}

export interface PrimaryProduct {
    altText: string;
    format: string;
    imageType: string;
    url: string;
}

export interface ImageTypeGallery {
    product: GalleryProduct
}

export interface GalleryProduct {
    altText: string;
    format: string;
    galleryIndex: number;
    imageType: string;
    url: string;
}

export interface Price {
    currencyIso: string;
    formattedValue: string;
    priceType: string;
    value: number;
}

export interface PriceRange {
    maxPrice: RangeOfPrice;
    minPrice: RangeOfPrice;
}

export interface RangeOfPrice {
    currencyIso: string;
    value: number;
}

export enum ProductVariant {
    NEW = '_N',
    REFURBISHED = '_R'
}

// {
//     "baseOptions": [
//       {
//         "options": [
//           {
//             "code": "50079_N",
//             "compatibility": [
//               "G5X",
//               "G8X"
//             ],
//             "discountPercentage": 12,
//             "discountPriceData": {
//               "currencyIso": "USD",
//               "formattedValue": "$52.80",
//               "priceType": "BUY",
//               "value": 52.8
//             },
//             "grossWeight": 0.4,
//             "grossWeightUnitCode": "KG",
//             "interval": 5,
//             "minQuantity": 0,
//             "name": "16 DIGITAL INPUTS CARD",
//             "netWeight": 0.4,
//             "netWeightUnitCode": "KG",
//             "obsoleteFlag": false,
//             "packageSize": 1,
//             "priceData": {
//               "currencyIso": "USD",
//               "value": 60
//             },
//             "quotationFlag": false,
//             "refurbished": false,
//             "sapBlocked": false,
//             "stock": {
//               "stockLevel": 0
//             },
//             "technology": [
//               "DTC"
//             ],
//             "url": "/Electrical/16-DIGITAL-INPUTS-CARD/p/50079_N",
//             "variantOptionQualifiers": []
//           },
//           {
//             "code": "50079_R",
//             "compatibility": [],
//             "discountPriceData": {
//               "currencyIso": "USD",
//               "formattedValue": "$0.00",
//               "priceType": "BUY",
//               "value": 0
//             },
//             "grossWeight": 0.4,
//             "grossWeightUnitCode": "KG",
//             "interval": 0,
//             "minQuantity": 0,
//             "name": "16 DIGITAL INPUTS CARD",
//             "netWeight": 0.4,
//             "netWeightUnitCode": "KG",
//             "obsoleteFlag": false,
//             "packageSize": 1,
//             "quotationFlag": true,
//             "refurbished": true,
//             "sapBlocked": false,
//             "stock": {
//               "stockLevel": 0
//             },
//             "technology": [],
//             "url": "/Electrical/16-DIGITAL-INPUTS-CARD/p/50079_R",
//             "variantOptionQualifiers": []
//           }
//         ],
//         "selected": {
//           "code": "50079_N",
//           "compatibility": [
//             "G5X",
//             "G8X"
//           ],
//           "discountPercentage": 12,
//           "discountPriceData": {
//             "currencyIso": "USD",
//             "formattedValue": "$52.80",
//             "priceType": "BUY",
//             "value": 52.8
//           },
//           "grossWeight": 0.4,
//           "grossWeightUnitCode": "KG",
//           "interval": 5,
//           "minQuantity": 0,
//           "name": "16 DIGITAL INPUTS CARD",
//           "netWeight": 0.4,
//           "netWeightUnitCode": "KG",
//           "obsoleteFlag": false,
//           "packageSize": 1,
//           "priceData": {
//             "currencyIso": "USD",
//             "value": 60
//           },
//           "quotationFlag": false,
//           "refurbished": false,
//           "sapBlocked": false,
//           "stock": {
//             "stockLevel": 0
//           },
//           "technology": [
//             "DTC"
//           ],
//           "url": "/Electrical/16-DIGITAL-INPUTS-CARD/p/50079_N",
//           "variantOptionQualifiers": []
//         },
//         "variantType": "SGREVariantProduct"
//       }
//     ],
//     "baseProduct": "50079",
//     "categories": [
//       {
//         "code": "ELECTRICAL",
//         "image": {
//           "url": "/medias/SG-icons-electrical-white.png?context=bWFzdGVyfHJvb3R8MTE1MzJ8aW1hZ2UvcG5nfGFEUTVMMmhtTXk4NU9UWXhNelUyTVRNeU16Z3lMMU5ITFdsamIyNXpMV1ZzWldOMGNtbGpZV3d0ZDJocGRHVXVjRzVufDlkMTExMzZlMjAzYWE2NmNmMWQyYmQzMDA4MDc5NDUwM2Q5N2U2NDQ4M2Y3MmU0MmZkZmFhN2YzYTZjM2QyNzE"
//         },
//         "name": "Electrical",
//         "url": "/Electrical/c/ELECTRICAL"
//       }
//     ],
//     "code": "50079_N",
//     "description": "<p>16 digital inputs card BH2331 This spare part is located in the Electrical Cabinet &amp; Rotor Electrical Cabinet subsystem</p>",
//     "images": {
//       "PRIMARY": {
//         "product": {
//           "altText": "16 DIGITAL INPUTS CARD",
//           "format": "product",
//           "imageType": "PRIMARY",
//           "url": "https://api.c4khy-siemensga1-d3-public.model-t.cc.commerce.ondemand.com/medias/?context=bWFzdGVyfGltYWdlc3w1ODUxMnxpbWFnZS9qcGVnfGFEZzBMMmhqWXk4NU1EazNPVFF3T0RJNE1Ua3d8YTQwNWQ5MzViMjE1NDQ3MWVjMzRhMGM5NjkwM2NlODUxOTdiZjA4MzhlZDc1YjIyOTRjZTY4ODdjOWZiMDE2NQ"
//         }
//       },
//       "GALLERY": [
//         {
//           "product": {
//             "altText": "16 DIGITAL INPUTS CARD",
//             "format": "product",
//             "galleryIndex": 0,
//             "imageType": "GALLERY",
//             "url": "https://api.c4khy-siemensga1-d3-public.model-t.cc.commerce.ondemand.com/medias/?context=bWFzdGVyfGltYWdlc3w1ODUxMnxpbWFnZS9qcGVnfGFEZzBMMmhqWXk4NU1EazNPVFF3T0RJNE1Ua3d8YTQwNWQ5MzViMjE1NDQ3MWVjMzRhMGM5NjkwM2NlODUxOTdiZjA4MzhlZDc1YjIyOTRjZTY4ODdjOWZiMDE2NQ"
//           }
//         }
//       ]
//     },
//     "multidimensional": false,
//     "name": "16 DIGITAL INPUTS CARD",
//     "nameHtml": "16 DIGITAL INPUTS CARD",
//     "numberOfReviews": 0,
//     "price": {
//       "formattedValue": "$60.00",
//       "currencyIso": "USD",
//       "priceType": "BUY",
//       "value": 60
//     },
//     "priceRange": {
//       "maxPrice": {
//         "currencyIso": "USD",
//         "value": 60
//       },
//       "minPrice": {
//         "currencyIso": "USD",
//         "value": 60
//       }
//     },
//     "purchasable": true,
//     "slug": "16-digital-inputs-card",
//     "stock": {
//       "stockLevel": 0,
//       "stockLevelStatus": "outOfStock"
//     },
//     "summary": "",
//     "url": "/Electrical/16-DIGITAL-INPUTS-CARD/p/50079_N"
// }